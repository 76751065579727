import { createApp } from 'vue'
import { Icon, Tabbar, TabbarItem, Search, Tab, Tabs, DropdownMenu, DropdownItem, Divider, Loading,
    Toast, Button, Field, CellGroup, Pagination, Dialog, Popup, Uploader, Overlay, Swipe, SwipeItem, BackTop } from 'vant'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import zhCn from "element-plus/es/locale/lang/zh-cn"
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus,{locale: zhCn})
app.use(Icon)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Search)
app.use(Tab)
app.use(Tabs)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Divider)
app.use(Toast)
app.use(Button)
app.use(Field)
app.use(CellGroup)
app.use(Pagination) 
app.use(Dialog)
app.use(Popup)
app.use(Uploader)
app.use(Overlay)
app.use(Swipe)
app.use(SwipeItem)
app.use(Loading)
app.use(BackTop)
app.use(store)
app.use(router)
app.mount('#app')
