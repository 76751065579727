<template>
  <div class="home">
    <div class="mainInfo">
      <div style="height:7vh;">
        <van-search placeholder="请输入搜索关键词" @click-input="searchBtn" />
      </div>
      <div style="height: 15vh;width:96%;margin: 1vh auto;border-radius: 10px;">
        <van-swipe :autoplay="3000" lazy-render>
          <van-swipe-item v-for="item in imagesList" :key="item.id">
            <img :src="item.imgUrl" style="height: 15vh;width:100%;background-size:100% 100%;border-radius: 10px;" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div style="height:13vh;">
        <el-row>
          <el-col :span="20">
            <van-tabs v-model:active="active" @click-tab="getSubject">
              <van-tab v-for="(item, index) in studyPhaseList" :key="index" :title="item.phaseName" :name="item"></van-tab>
            </van-tabs>
          </el-col>
          <el-col :span="4">
            <van-dropdown-menu ref="menuRef">
              <van-dropdown-item title="筛选">
                <div style="max-height:18rem;overflow-y: auto;">
                  <el-row style="padding:10px">
                    <el-col :span="12" style="text-align:left;color:#999999;">年级</el-col>
                    <el-col :span="12" style="text-align:right;color:#999999;font-size:0.8rem;" v-show="zsInfoTwo" @click="grBtn(false)">展开<van-icon name="arrow-down" /></el-col>
                    <el-col :span="12" style="text-align:right;color:#999999;font-size:0.8rem;" v-show="!zsInfoTwo" @click="grBtn(true)">收起<van-icon name="arrow-up" /></el-col>
                  </el-row>
                  <el-row style="padding:10px;height:52px;overflow: hidden;" v-show="zsInfoTwo">
                    <el-col :span="6" style="margin-bottom:10px;">
                      <p class="listInfo" @click="setGrade('')" :class="idxFour==''?'active':''">全部</p>
                    </el-col>
                    <el-col :span="6" v-for="(item, index) in gradeList" :key="index">
                      <p class="listInfo" @click="setGrade(item.id)" :class="idxFour==item.id?'active':''">{{item.gradeName}}</p>
                    </el-col>
                  </el-row>
                  <el-row style="padding:10px 10px 0 10px;" v-show="!zsInfoTwo">
                    <el-col :span="6" style="margin-bottom:10px;">
                      <p class="listInfo" @click="setGrade('')" :class="idxFour==''?'active':''">全部</p>
                    </el-col>
                    <el-col :span="6" v-for="(item, index) in gradeList" :key="index">
                      <p class="listInfo" @click="setGrade(item.id)" :class="idxFour==item.id?'active':''">{{item.gradeName}}</p>
                    </el-col>
                  </el-row>
                  <el-row style="padding:10px">
                    <el-col :span="12" style="text-align:left;color:#999999;">类别</el-col>
                    <el-col :span="12" style="text-align:right;color:#999999;font-size:0.8rem;" v-show="zsInfo" @click="zkBtn(false)">展开<van-icon name="arrow-down" /></el-col>
                    <el-col :span="12" style="text-align:right;color:#999999;font-size:0.8rem;" v-show="!zsInfo" @click="zkBtn(true)">收起<van-icon name="arrow-up" /></el-col>
                  </el-row>
                  <el-row style="padding:10px;height:52px;overflow: hidden;" v-show="zsInfo">
                    <el-col :span="6" style="margin-bottom:10px;">
                      <p class="listInfo" @click="setCategory('')" :class="idx==''?'active':''">全部</p>
                    </el-col>
                    <el-col :span="6" v-for="(item, index) in categoryList" :key="index">
                      <p class="listInfo" @click="setCategory(item.id)" :class="idx==item.id?'active':''">{{item.categoryName}}</p>
                    </el-col>
                  </el-row>
                  <el-row style="padding:10px 10px 0 10px;" v-show="!zsInfo">
                    <el-col :span="6" style="margin-bottom:10px;">
                      <p class="listInfo" @click="setCategory('')" :class="idx==''?'active':''">全部</p>
                    </el-col>
                    <el-col :span="6" v-for="(item, index) in categoryList" :key="index">
                      <p class="listInfo" @click="setCategory(item.id)" :class="idx==item.id?'active':''">{{item.categoryName}}</p>
                    </el-col>
                  </el-row>
                  <el-row style="padding:10px">
                    <el-col :span="12" style="text-align:left;color:#999999;">年份</el-col>
                    <el-col :span="12" style="text-align:right;;color:#999999;font-size:0.8rem" v-show="zsInfoOne" @click="zkBtnOne(false)">展开<van-icon name="arrow-down" /></el-col>
                    <el-col :span="12" style="text-align:right;;color:#999999;font-size:0.8rem" v-show="!zsInfoOne" @click="zkBtnOne(true)">收起<van-icon name="arrow-up" /></el-col>
                  </el-row>
                  <el-row style="padding:10px;height:52px;overflow: hidden;" v-show="zsInfoOne">
                      <el-col :span="6" style="margin-bottom:10px;">
                        <p class="listInfo" @click="setYear('')" :class="idxOne==''?'active':''">全部</p>
                      </el-col>
                      <el-col :span="6" v-for="(item, index) in yearList" :key="index" style="margin-bottom:10px;">
                        <p class="listInfo" @click="setYear(item.id)" :class="idxOne==item.id?'active':''">{{item.yearNo}}</p>
                      </el-col>
                  </el-row>
                  <el-row style="padding:10px 10px 0 10px;" v-show="!zsInfoOne">
                      <el-col :span="6" style="margin-bottom:10px;">
                        <p class="listInfo" @click="setYear('')" :class="idxOne==''?'active':''">全部</p>
                      </el-col>
                      <el-col :span="6" v-for="(item, index) in yearList" :key="index" style="margin-bottom:10px;">
                        <p class="listInfo" @click="setYear(item.id)" :class="idxOne==item.id?'active':''">{{item.yearNo}}</p>
                      </el-col>
                  </el-row>
                  <el-row style="padding:10px">
                    <el-col :span="24" style="text-align:left;color:#999999;">排序</el-col>
                    <el-col :span="6" v-for="(item, index) in sequenceList" :key="index" style="margin-top:1rem;">
                      <p class="listInfo" @click="setSequence(item.id)" :class="idxTwo==item.id?'active':''">{{item.name}}</p>
                    </el-col>
                  </el-row>
                </div>
                <div class="btnInfo">
                  <p style="background-color: #e2e6ee;color:#7f7f7f;" @click="resetting">重置</p>
                  <p style="background-color: #3c7cfb;color:white;" @click="sureBtn">确认</p>
                </div>
              </van-dropdown-item>
            </van-dropdown-menu>
          </el-col>
        </el-row>
        <van-tabs v-model:active="activeOne" @click-tab="changeSubject">
          <van-tab title="全部" name=""></van-tab>
          <van-tab v-for="(item, index) in subjectList" :key="index" :title="item.subjectName" :name="item.subjectId"></van-tab>
        </van-tabs>
      </div>
      <div style="height:56vh;width:96%;margin:0 auto 10px;overflow-y: auto;">
        <el-empty description="暂无数据" v-if="coursewareList.length==0" />
        <div v-loading="loading" element-loading-text="数据加载中..."
        v-for="(itemCou, index) in coursewareList" :key="index" class="contentInfo">
          <el-row @click="gotoPage(itemCou.id, itemCou.subjectId)">
            <el-col :span="18" style="text-align: left;">
              <span class="titleInfo">{{itemCou.subjectName.slice(0, 1)}}</span>
              <span style="font-weight:bold;">{{itemCou.coursewareName}}</span>
            </el-col>
            <el-col :span="6" style="text-align: right;">
              <span v-if="itemCou.originalPrice==0" style="color:red">免费</span>
              <div v-if="itemCou.originalPrice!=0">
                <p style="color:#fa6c13;font-weight:bold;font-size:1.1rem;">￥{{itemCou.originalPrice}}</p>
                <p v-if="itemCou.memberFree==1" style="color:orange;margin-top:-0.8rem">会员免费</p>
              </div>              
            </el-col>
            <!-- <el-col :span="24" style="text-align: left;color:#a4a3a3;">
              <span>合集：</span>
              <span>{{itemCou.previewNum}}</span>
            </el-col> -->
            <el-col :span="12" style="text-align: left;color:#a4a3a3;">
              <span>浏览量：</span>
              <span>{{itemCou.previewNum}}</span>
              <span style="margin-left:3.5rem">下载量:</span>
              <span>{{itemCou.downloadNum}}</span>
            </el-col>
            <el-col :span="12" style="text-align: right;color:#a4a3a3;font-size:13px">
              <span>上传时间：{{itemCou.createTime}}</span>
            </el-col>
            </el-row>
            <el-row style="padding-top:15px;border-top:1px solid #dbdbdb;">
              <el-col :span="12" style="text-align: left;display: flex;justify-content: flex-start;">
                <p @click="addCollect(itemCou.id)" v-if="itemCou.collect==0" style="margin-right:20px">
                  <el-icon :size="16" style="top:2px" >
                    <Star />
                  </el-icon>
                    <span style="font-size:13px;">收藏</span>
                  </p>
                  <p @click="cancellCollect(itemCou.id)" v-if="itemCou.collect==1" style="margin-right:20px">
                    <el-icon :size="16" color="#ffbe6e" style="top:2px">
                      <StarFilled />
                    </el-icon>
                    <span style="font-size:13px;">已收藏</span>
                  </p>
                  <p @click="addInformation(itemCou.id)" v-if="itemCou.addCart==0 && itemCou.originalPrice!=0">
                    <el-icon :size="16" :color="color" style="top:3px" >
                    <ShoppingCart />
                  </el-icon>
                    <span style="font-size:13px;">加资料蓝</span>
                  </p>
                  <p @click="cancellInformation(itemCou.id)" v-if="itemCou.addCart==1 && itemCou.originalPrice!=0">
                    <el-icon :size="16" color="#ffbe6e" style="top:3px" >
                    <ShoppingCartFull />
                  </el-icon>
                    <span style="font-size:13px;">已加资料蓝</span>
                  </p>
                </el-col>
                <el-col :span="12" style="text-align: right;">
                  <span class="buttonInfoOne" @click="collectBtn(itemCou)">下载</span>
                </el-col>
            </el-row>
        </div>
      </div>
    </div>
    <div class="bottomInfo">
      <BottomInfo @idsInfo="ChangeIds" :datas="datas"></BottomInfo>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { showSuccessToast, showToast } from 'vant'
import BottomInfo from './BottomInfo.vue'
import {getCoursewareList, addCollet, removeCollet, addShopCars, removeShopCars,
getStudyPhaseList, getCategoryList, getYearList, shareCourseWare, wxLogin,
getWxShareAccredit, wxPayOrder, listBanner, getGrade, upCousewaraPreview } from '@/http/api'
export default {
  name: 'HomeView',
  components: {
    BottomInfo
  },
  data() {
    return {
      code: '',
      qrCode: '',
      orderInfo: '',
      idx:'',
      idxOne:'',
      idxTwo:'',
      idxThree: '',
      idxFour: '',
      subjectId: '',
      datas: 1,
      columnIds: '',
      studyPhaseList: [],
      coursewareList: [],
      imagesList: [],
      active: 0,
      subjectList: [],
      loading: false,
      questInfo: '全部',
      sequenceList: [
        { id: 0, name: '浏览量' },
        { id: 1, name: '下载' },
        { id: 2, name: '时间' },
      ],
      zsInfo: true,
      zsInfoOne: true,
      zsInfoTwo: true,
      pageInfo: {
        pageSize: 50,
        pageCurrent: 1,
        pageTatol: null,
      },
      categoryList: [],
      gradeList: [],
      yearList: [],
    }
  },
  created() {
    // this.getTest()
    this.getUrlCode()
    this.getShareInfo()
    this.getBanner()
    this.getStudyPhase()
    this.getCategory() // 获取类别
    this.getYear()
  },
  methods: {
    getTest() {
      let token = ''
      localStorage.setItem('token', token)
    },
    // 截取url中的code方法
    getUrlCode() {
      var url = window.location.href
      if (url.includes("code")) {
        this.code = url.split('code')[1].split('=')[1].split('&')[0]
      }
      if (url.includes("qrCode")) {
        this.qrCode = url.split('qrCode')[1].split('=')[1].split('&')[0]
      } else {
        this.qrCode = ''
      }
      if (this.code==''||this.code==null) {
        const local = window.location.href // 获取页面url
        // 判断是否是微信环境
        const ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          const appid = 'wxfa4b54818ba0f590'
          const redirectUrl = encodeURIComponent(local)
          const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1`
          window.location.href = url
        } else {
          showSuccessToast('请在微信中打开')
        }
      } else {
        this.getLogin()
      }
    },
    // 登录
    getLogin() {
      let that = this
      let data = {
        code: that.code,
        qrCode: that.qrCode
      }
      wxLogin(data).then(res=>{
        if (res.data.code==200) {
          localStorage.setItem('token', res.data.data.token)
          that.getuser()
        }
      }).catch(err=>{})
    },
    getuser() {
      var url = window.location.href
        if (url.includes("orderId")) {
          this.orderInfo = url.split('orderId')[1].split('=')[1]
          this.wxPay(this.orderInfo)
        } else {
          this.orderInfo = ''
        }
      // getUserInfo().then(ress=>{
      //   if (ress.data.code==200) {
      //     this.userInfo = ress.data.data
      //     localStorage.setItem('userInfo', JSON.stringify(ress.data.data))
      //     var url = window.location.href
      //     if (url.includes("orderId")) {
      //       this.orderInfo = url.split('orderId')[1].split('=')[1]
      //       this.wxPay(this.orderInfo)
      //     } else {
      //       this.orderInfo = ''
      //     }
      //   }
      // })
    },
    getBanner() {
      listBanner().then(res=>{
        if (res.data.code==200) {
          this.imagesList = res.data.data
        }
      })
    },
    // 微信支付
    wxPay(orderInfo) {
      wxPayOrder(orderInfo, 1).then(res=>{
        if (res.data.code==200) {
          const config = res.data.data
            wx.config({
              appId: config.appid,  // appID 公众号的唯一标识
              timestamp: config.timeStamp, // 生成签名的时间戳
              nonceStr: config.nonceStr, //  生成签名的随机串
              signature: config.paySign, // 生成的签名
              jsApiList: ['chooseWXPay', 'checkJsApi']
            });
            wx.ready(() => {
              wx.chooseWXPay({
                appId: config.appid,  // appID 公众号的唯一标识
                timestamp: config.timeStamp, // 生成签名的时间戳
                nonceStr: config.nonceStr, //  生成签名的随机串
                paySign: config.paySign, // 生成的签名
                signType: config.signType,
                package: config.packagePrepayId,
                success: function () {
                  showToast({
                    message: '支付成功',
                    icon: 'passed',
                  })
                },
                fail: function () {
                  showToast({
                    message: '支付失败',
                    icon: 'close',
                  });
                },
                cancel: function () {
                  showToast({
                    message: '取消支付。',
                    icon: 'close',
                  });
                }
              })
            })
        } else {
          showToast({
            message: res.data.message,
            icon: 'close',
          });
        }
      })
    },
    zkBtn(item) {
      this.zsInfo = item
    },
    zkBtnOne(item) {
      this.zsInfoOne = item
    },
    grBtn(item) {
      this.zsInfoTwo = item
    },
    ChangeIds(v) {
      this.columnIds = v
    },
    setGrade(item) {
      this.idxFour = item
    },
    setCategory(item) {
      this.idx = item
    },
    setYear(item) {
      this.idxOne = item
    },
    setSequence(item) {
      this.idxTwo = item
    },
    resetting() {
      this.idx = ''
      this.idxOne = ''
      this.idxTwo = ''
      this.idxFour = ''
      this.$refs.menuRef.close()
      this.getCourseware()
    },
    sureBtn() {
      this.$refs.menuRef.close()
      this.getCourseware()
    },
    getStudyPhase() {
      getStudyPhaseList().then(res=>{
        if (res.data.code==200) {
            this.studyPhaseList = res.data.data
            this.idxThree = res.data.data[0].studyPhaseId
            this.subjectList = res.data.data[0].subjectList
            this.getGradeInfo()
            this.getCourseware() // 获取课件列表
          }
        }).catch(err=>{})
    },
    getSubject(item) {
      this.subjectId = ''
      this.idxThree = item.name.studyPhaseId
      this.subjectList = item.name.subjectList
      this.getGradeInfo()
      this.getCourseware()
    },
    changeSubject(item) {
      this.subjectId = item.name
      this.getCourseware()
    },
    // 获取年级
    getGradeInfo() {
      getGrade(this.idxThree).then(res=>{
        this.gradeList = res.data.data
      }).catch(err=>{})
    },
    // 获取类别
    getCategory() {
      getCategoryList(1).then(res=>{
        if (res.data.code==200) {
          this.categoryList = res.data.data
        }
      })
    },
    // 获取年份
    getYear() {
      getYearList().then(res=>{
        if (res.data.code==200) {
          this.yearList = res.data.data
        }
      })
    },
    // 获取课件列表
    getCourseware() {
      this.coursewareList = []
        let data = {
            columnId: 1,
            stageId: this.idxThree,
            categoryId: this.idx,
            gradeId: this.idxFour,
            yearId: this.idxOne,
            subjectId: this.subjectId,
            sort: this.idxTwo,
            page: this.pageInfo.pageCurrent,
            pageSize: this.pageInfo.pageSize
        }
        getCoursewareList(data).then(res=>{
          if (res.data.code==200) {
            this.coursewareList = res.data.data.rows
            this.pageInfo.pageTatol = res.data.data.total
            this.loading = false
          }
        }).catch(err=>{})
    },
    // 添加课件收藏
    addCollect(id) {
      let arrayp = []
      arrayp.push(id)
      addCollet(arrayp).then(res=>{
        if (res.data.code == 200) {
          showSuccessToast(res.data.message)
          this.$nextTick().then(() => {  
            this.getCourseware()  
          })
        }
      }).catch(err=>{})
    },
    // 取消课件收藏
    cancellCollect(id) {
      removeCollet(id).then(res=>{
        if (res.data.code == 200) {
          showSuccessToast(res.data.message)
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 添加资料蓝
    addInformation(id) {
      addShopCars(id).then(res=>{
        if (res.data.code == 200) {
          showSuccessToast(res.data.message)
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 取消资料蓝
    cancellInformation(id) {
      removeShopCars(id).then(res=>{
        if (res.data.code == 200) {
          showSuccessToast(res.data.message)
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 下载
    collectBtn(item) {
      if (item.originalPrice==0) {
        this.$router.push({ path: '/downloadview', query: {from: JSON.stringify(item)} })
      } else {
        this.$router.push({ path: '/settlementview', query: {from: JSON.stringify(item), title: 'home'} })
      }
    },
    gotoPage(id, subjectId) {
      upCousewaraPreview(id).then(res=>{
        if (res.data.code==200) {
          this.$router.push({ path: '/detailsfrom', query:{ detailsId: id, detailsSubjectId: subjectId } })
        }
      })
    },
    searchBtn() {
      this.$router.push({ path: '/serchview' })
    },
    // 分享
    getShareInfo() {
      let save = window.location.href.split('#')[0] // 只需要传当前页面地址
      getWxShareAccredit(save).then(res => {
        if (res.data.code==200) {
          const config = res.data.data
          wx.config({
            appId: config.appId,  // appID 公众号的唯一标识
            timestamp: config.timestamp, // 生成签名的时间戳
            nonceStr: config.nonceStr, //  生成签名的随机串
            signature: config.signature, // 生成的签名
            jsApiList: [
              'updateAppMessageShareData', // 分享到朋友
              'updateTimelineShareData', // 分享到朋友圈
              'onMenuShareAppMessage',
              'onMenuShareTimeline',
            ]
          });
          wx.ready(() => {
            var shareData = {
              title: '初心课件',
              desc: '初心教育',
              link: window.location.href, // 分享后的地址
              imgUrl: 'https://alioss.proxy.bjchuxinjiaoyu.com/2023/12/27/1739948597706809345.png',
              success: function() {
                shareCourseWare().then(res=>{
                  if (res.data.code==200) {
                    console.log(res.data)
                  }
                })
              },
            };
            //点击要去分享
            wx.onMenuShareTimeline(shareData)
            wx.onMenuShareAppMessage(shareData)
            // wx.updateAppMessageShareData(shareData)
            // wx.updateTimelineShareData(shareData)
            // wx.onMenuShareAppMessage(shareData)
            // wx.onMenuShareTimeline(shareData)
          });
          wx.error(function (res) {
            console.log("微信分享错误信息", res);
          })
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.home{
  height: 100vh;width: 100%;background-color: #f5f3f3;position: fixed;
  .mainInfo{
    width:100%;
    .contentInfo{
      padding: 10px;font-size: 0.8rem;background-color:white;
      margin-bottom: 10px;line-height: 1.9rem;
      border-radius: 5px;
      .titleInfo{
        background: rgb(74, 64, 50);color: white;
        padding: 3px;border-radius: 3px;margin-right: 5px;
      }
      .buttonInfoOne{
        background: #3c7cfb;padding: 10px 20px;
        color: white;border-radius: 30px;
      }
    }
    .listInfo{
      width: 5.5rem;height: 2rem;background-color: #f1f5f9;
      text-align: center;line-height: 2rem;
    }
    .btnInfo{
      padding: 0.5rem 1rem;
      display: flex;justify-content: space-between;
      p{
        width: 40%;height: 2.2rem;border-radius: 1.1rem;line-height: 2.2rem;
      }
    }
  }
  .active{
    color: #3c7cfb;
  }
}
</style>
