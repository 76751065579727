<template>
  <div>
    <el-row>
        <el-col :span="5" v-for="item in colList" :key="item.id" @click="changeTabs(item.id)" :class="ids==item.id?'active':''">
          <Edit style="width: 1.2rem; height: 1.5rem;margin-bottom:-5px" />
          <p>{{item.columnName}}</p>
        </el-col>
        <el-col :span="5" @click="changeTabs(10)" :class="ids==10?'active':''">
          <ShoppingCart style="width: 1.2rem; height: 1.5rem;margin-bottom:-5px" />
          <p>资料蓝</p>
        </el-col>
        <el-col :span="4" @click="changeTabs(11)" :class="ids==11?'active':''">
          <User style="width: 1.2rem; height: 1.5rem;margin-bottom:-5px" />
          <p>我的</p>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {getColumuList} from '@/http/api'
export default {
  name: 'BottomInfo',
  props: ['datas'],
  data() {
    return{
      colList: [],
      ids: this.datas,
    }
  },
  created() {
    this.getColumu()
  },
  methods:{
    getColumu() {
      getColumuList().then(res=>{
        this.colList = res.data.data
        // this.ids = res.data.data[0].id
      })
    },
    changeTabs(item) {
      this.$emit('idsInfo',item)
      if (item==1) {
        this.$router.push({ path: '/' })
      }else if (item==2) {
        this.$router.push({ path: '/preparationinfo' })
      }else if (item==4) {
        this.$router.push({ path: '/preferredinfo' })
      } else if (item==10) {
        this.$router.push({ path: '/basketInfo' })
      } else if (item==11) {
        this.$router.push({ path: '/myview' })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.active{
  color: #3fa7f6;
}
</style>
