import { Service } from "./Service";

// 微信登录
export function wxLogin(data) {
    return Service({
        url: 'courseware/kjUser/wxLogin',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 获取微信分享授权
export function getWxShareAccredit(signatureUrls) {
    return Service({
        url: 'wxShare/getWxShareAccredit',
        method: 'get',
        params: { signatureUrl: signatureUrls}
    })
}
// 查询用户详情
export function getUserInfo() {
    return Service.get('courseware/kjUser/getUserInfo')
}
// 查询学段列表和科目
export function getStudyPhaseList() {
    return Service.get('courseware/kjStudyPhase/getStudyPhaseList')
}
// 获取年级
export function getGrade(idInfo) {
    return Service({
        url: 'courseware/kjGrade/getGradeList',
        method: 'get',
        params: { studyPhaseId: idInfo }
    })
}
// 获取栏目
export function getColumuList() {
    return Service.get('courseware/kjColumn/getColumuList')
}
// 获取类别
export function getCategoryList(idInfo) {
    return Service({
        url: 'courseware/kjCategory/getCategoryList',
        method: 'get',
        params: { columnId: idInfo }
    })
}
// 获取年级
export function getYearList() {
    return Service.get('courseware/kjYears/getYearList')
}
// 查询课件列表
export function getCoursewareList(data) {
    return Service({
        url: 'courseware/kjCourseware/getCoursewareList',
        method: 'get',
        params: data
    })
}
// 查询课题列表
export function getQuestionBankList(data) {
    return Service({
        url: 'courseware/tkQuestionBank/getQuestionBankList',
        method: 'get',
        params: data
    })
}
// 根据题库id查询题目
export function getTopicList(data) {
    return Service({
        url: 'courseware/tkTopic/getTopicList',
        method: 'get',
        params: data
    })
}
// 查询题目详情
export function getTopicInfo(topicIds) {
    return Service({
        url: 'courseware/tkTopic/getTopicInfo',
        method: 'get',
        params: { topicId: topicIds }
    })
}
// 查询用户错题记录列表
export function getUserErrorTopicList(data) {
    return Service({
        url: '/courseware/tkUserErrorTopic/getUserErrorTopicList',
        method: 'get',
        params: data
    })
}
// 增加课件预览量
export function upCousewaraPreview(coursewareId) {
    return Service({
        url: 'courseware/kjCourseware/upCousewaraPreview/'+coursewareId,
        method: 'put',
    })
}
// 增加课件下载量
export function upCousewaraDownload(coursewareId) {
    return Service({
        url: 'courseware/kjCourseware/upCousewaraDownload/'+coursewareId,
        method: 'put',
    })
}
// 添加用户错题记录
export function addUserErrorTopic(data) {
    return Service({
        url: 'courseware/tkUserErrorTopic/addUserErrorTopic',
        method: 'post',
        data: data
    })
}

// 根据题目id查询题目答案
export function getTopicAnswerList(data) {
    return Service({
        url: 'courseware/tkTopicAnswer/getTopicAnswerList',
        method: 'get',
        params: data
    })
}
// 添加课件收藏
export function addCollet(data) {
    return Service({
        url: 'courseware/kjUserCollect/addCollet',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 删除课件收藏
export function removeCollet(coursewareId) {
    return Service({
        url: 'courseware/kjUserCollect/removeCollet/'+coursewareId,
        method: 'delete',
    })
}
// 获取专辑
export function getAlbumList(data) {
    return Service({
        url: 'courseware/kjAlbum/getAlbumList',
        method: 'get',
        params: data
    })
}
// 添加用户资料栏（购物车）
export function addShopCars(coursewareId) {
    return Service({
        url: 'courseware/kjShopCars/addShopCars/'+coursewareId,
        method: 'post',
    })
}
// 批量/单个删除课用户资料栏（购物车）
export function removeShopCars(coursewareIds) {
    return Service({
        url: 'courseware/kjShopCars/removeShopCars/'+coursewareIds,
        method: 'delete',
    })
}
// 查询我的资料栏（购物车）
export function getShopCarsList(data) {
    return Service({
        url: 'courseware/kjShopCars/getShopCarsList',
        method: 'get',
        params: data
    })
}
// 查询我的收藏列表
export function getCollectList(data) {
    return Service({
        url: 'courseware/kjUserCollect/getCollectList',
        method: 'get',
        params: data
    })
}
// 查询课件详情
export function getCoursewareInfo(courId) {
    return Service({
        url: 'courseware/kjCourseware/getCoursewareInfo',
        method: 'get',
        params: { coursewareId: courId}
    })
}
// 查询课件文件
export function getPreviewImages(imges) {
    return Service({
        url: 'courseware/kjCourseware/getPreviewImages/'+imges,
        method: 'get',
    })
}
// 获取下载文件列表
export function getDownloadInfo(courId) {
    return Service({
        url: 'courseware/kjCourseware/getDownloadInfo/'+courId,
        method: 'get',
    })
}
// 下载课件
export function downloadCousewara(fileId) {
    return Service({
        url: 'courseware/kjCourseware/getDownLoadUrl/'+fileId,
        method: 'get',
    })
}
// 获取热门搜索词
export function getHotSearchList() {
    return Service.get('courseware/kjHotSearch/getHotSearchList')
}
// 查询会员套餐列表
export function getVipPackageList() {
    return Service.get('courseware/kjVipPackage/getVipPackageList')
}
// 创建购买会员套餐订单
export function createMealOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createMealOrder',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 创建课件订单
export function createCourseOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createCourseOrder',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 创建钻石充值订单
export function createDiamondTopUpOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createDiamondTopUpOrder',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 创建充值订单
export function createTopUpOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createTopUpOrder',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 微信支付订单
export function wxPayOrder(orderId, wxPayType) {
    return Service({
        url: 'courseware/kjOrder/wxPayOrder/'+orderId+'/'+wxPayType,
        method: 'post',
    })
}
// 账户支付订单
export function accountPayOrder(orderId) {
    return Service({
        url: 'courseware/kjOrder/accountPayOrder/'+orderId,
        method: 'post',
    })
}
// 蓝钻余额支付订单
export function diamondPayOrder(orderId) {
    return Service({
        url: 'courseware/kjOrder/diamondPayOrder/'+orderId,
        method: 'post',
    })
}
// 查询蓝钻充值金额配置
export function getDiamondRechargeConfigList() {
    return Service.get('courseware/kjDiamondRechargeConfig/getDiamondRechargeConfigList')
}
// 查询用户等级列表
export function getUserLevelConfigList() {
    return Service.get('courseware/kjUserLevelConfig/getUserLevelConfigList')
}
// 查询用户蓝钻余额变动记录列表
export function getUserDiamondBalanceRecordList(data) {
    return Service({
        url: 'courseware/kjUserDiamondBalanceRecord/getUserDiamondBalanceRecordList',
        method: 'get',
        params: data,
    })
}
// 获取积分记录列表
export function getUserIntegralList(data) {
    return Service({
        url: 'courseware/kjUserIntegralRecord/getUserIntegralList',
        method: 'get',
        params: data,
    })
}
// 修改用户资料
export function updeleteUserInfo(data) {
    return Service({
        url: 'courseware/kjUser/upUserInfo',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 修通用上传请求（单个）
export function uploadInfo(data) {
    return Service({
        url: 'common/upload',
        method: 'post',
        headers: {
            "Content-Type": "application/form-data"
        },
        data: data
    })
}
// 添加用户积分/完善资料/分享
// export function addUserIntegral(changeType) {
//     return Service({
//         url: 'courseware/kjUserIntegralRecord/addUserIntegral/'+changeType,
//         method: 'post',
//     })
// }
// 用户分享分享
export function shareCourseWare() {
    return Service({
        url: 'courseware/kjUser/shareCourseWare',
        method: 'get',
    })
}
// 获取订单列表
export function getOrderList(data) {
    return Service({
        url: 'courseware/kjOrder/getOrderList',
        method: 'get',
        params: data
    })
}
// 获取订单详情
export function getorderStatus(orderId) {
    return Service({
        url: 'courseware/kjOrder/getOrderInfo/'+orderId,
        method: 'get',
    })
}
// 查询banner列表
export function listBanner() {
    return Service({
        url: 'courseware/kjBanner/listBanner',
        method: 'get',
    })
}