<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
    name: 'App',
    provide() {
        return {
          reload: this.reload,
        }
    },
    data() {
        return {
            isRouterAlive: true,
        }
    },
    created() {},
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
         })
      },
    },
}
</script>
<style lang="scss">
*{
  padding: 0;margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
li{
  list-style-type: none;
}
.bottomInfo{
  width: 100%;min-height: 6vh;border-top: 1px solid #e1e1e1;
  position: absolute;bottom: 0;font-size: 0.8rem;
  background-color: white;padding-top: 1vh;
}
</style>
