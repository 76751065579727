import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/preferredinfo',
    name: 'preferredinfo',
    component: () => import('../views/PreferredInfo.vue')
  },
  {
    path: '/basketInfo',
    name: 'basketInfo',
    component: () => import('../views/BasketInfo.vue')
  },
  {
    path: '/myview',
    name: 'myview',
    component: () => import('../views/MyView.vue')
  },
  {
    path: '/questionbank',
    name: 'questionbank',
    component: () => import('../views/QuestionBank.vue')
  },
  {
    path: '/levelview',
    name: 'levelview',
    component: () => import('../views/LevelView.vue')
  },
  {
    path: '/userview',
    name: 'userview',
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/rechargeview',
    name: 'rechargeview',
    component: () => import('../views/RechargeView.vue')
  },
  {
    path: '/detailsfrom',
    name: 'detailsfrom',
    component: () => import('../views/DetailsFrom.vue')
  },
  {
    path: '/settlementview',
    name: 'settlementview',
    component: () => import('../views/SettlementView.vue')
  },
  {
    path: '/detailsview',
    name: 'detailsview',
    component: () => import('../views/DetailsView.vue')
  },
  {
    path: '/changequestion',
    name: 'changequestion',
    component: () => import('../views/ChangeQuestion.vue')
  },
  {
    path: '/wrongquestion',
    name: 'wrongquestion',
    component: () => import('../views/WrongQuestion.vue')
  },
  {
    path: '/vipview',
    name: 'vipview',
    component: () => import('../views/VipView.vue')
  },
  {
    path: '/downloadview',
    name: 'downloadview',
    component: () => import('../views/DownloadView.vue')
  },
  {
    path: '/serchview',
    name: 'serchview',
    component: () => import('../views/SerchVIew.vue')
  },
  {
    path: '/preparationinfo',
    name: 'preparationinfo',
    component: () => import('../views/PreparationInfo.vue')
  },
  {
    path: '/recordlist',
    name: 'recordlist',
    component: () => import('../views/RecordList.vue')
  },
  {
    path: '/illustrateview',
    name: 'illustrateview',
    component: () => import('../views/IllustrateView.vue')
  },
  {
    path: '/levelillustrte',
    name: 'levelillustrte',
    component: () => import('../views/LevelIllustrte.vue')
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    component: () => import('../views/OrderList.vue')
  },
  {
    path: '/orderdetails',
    name: 'orderdetails',
    component: () => import('../views/OrderDetails.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
